import React, { useEffect } from "react"
import clsx from "clsx"
import { graphql } from "gatsby"
import MDReactComponent from "markdown-react-js"

// @Material UI
import { makeStyles } from "@material-ui/core/styles"
import Typography from "@material-ui/core/Typography"
import Grid from "@material-ui/core/Grid"
import Skeleton from "@material-ui/lab/Skeleton"
import Paper from "@material-ui/core/Paper"
import Avatar from "@material-ui/core/Avatar"
import Collapse from "@material-ui/core/Collapse"
import IconButton from "@material-ui/core/IconButton"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import ExpandLessIcon from "@material-ui/icons/ExpandLess"

// @Firebase
import firebase from "gatsby-plugin-firebase"

// @Local
import Layout from "../components/layout"
import SEO from "../components/seo"
import TopButtons from "../components/singles/topButtons"
import BlockNews from "../components/singles/latestNews"
import { LikeButton, FavButton } from "../components/actions/Actions"

const stl = makeStyles(theme => ({
  wrapperCard: {
    margin: "0 auto",
    marginBottom: 20,
  },
  card: {
    position: "relative",
  },
  cardActions: {
    display: "flex",
    justifyContent: "center",
  },
  avatar: {
    width: 120,
    minHeight: 120,
    borderRadius: 0,
    borderTopLeftRadius: 4,
    borderBottomLeftRadius: 4,
    borderColor: "#fefefe",
  },
  avatarOpen: {
    margin: "0 auto",
    marginTop: 5,
    borderRadius: 60,
  },
  name: {
    fontSize: 15,
    fontWeight: 500,
    color: "#5b5656",
    textTransform: "uppercase",
  },
  email: {
    fontSize: 12,
    color: "#5b5656",
    fontWeight: 300,
  },
  ocupation: {
    fontSize: 12,
    color: "#5b5656",
    fontWeight: 100,
  },
  phone: {
    fontSize: 12,
    color: "#d5392d",
    fontWeight: 300,
  },
  wrapperHeadingText: {
    textAlign: "center",
    marginTop: 50,
    marginBottom: 100,
  },
  headingText: {
    color: "#5b5656",
    fontWeight: 300,
    fontSize: 25,
  },
  contentCollapsed: {
    color: "#5b5656",
    fontWeight: 100,
    padding: 20,
    fontSize: 15,
  },
  expandIcons: {
    width: "100%",
    textAlign: "center",
  },
  iconExpand: {
    fontSize: 40,
  },
}))

const Card = ({ data, type, strapiId }) => {
  const classes = stl()
  const [userData, setUserData] = React.useState(null)
  const [open, setOpen] = React.useState(false)
  const [loading, setLoading] = React.useState(false)

  useEffect(() => {
    const mount = true;
    if (mount) {
      if (!userData && data.email) {
        firebase
          .firestore()
          .collection("DTVecolDirectory")
          .doc(data.email)
          .get()
          .then(snapshot => {
            if (snapshot) {
              setUserData(snapshot.data())
              setLoading(false)
            }
          })
      } else {
        setLoading(false)
      }
    }
    return () => {
      mount = false;
    }
    setLoading(true)
  }, [userData, data])

  if (loading) {
    return (
      <Grid item xs={10} className={classes.wrapperCard}>
        <Paper className={classes.card}>
          <Grid container alignItems={"center"}>
            <Grid item xs={3}>
              <Skeleton variant="circle" className={classes.avatar} />
            </Grid>
            <Grid item xs={9} className={classes.cardContent}>
              <Skeleton variant="rect" width={210} height={20} border={5} />
              <Skeleton variant="text" width={100} />
              <Skeleton variant="text" width={150} />
              <Skeleton variant="text" width={80} />
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    )
  }

  return (
    <Grid item xs={10} className={classes.wrapperCard}>
      {userData && userData.email ? (
        <Paper className={classes.card}>
          <Grid container alignItems={"center"}>
            <Grid item xs={3}>
              {userData._photoURL ? (
                <Avatar
                  sizes={"large"}
                  alt={userData.name}
                  src={userData._photoURL}
                  className={clsx(
                    classes.avatar,
                    "dtTransition",
                    open ? classes.avatarOpen : null
                  )}
                />
              ) : (
                <Avatar
                  alt={userData.name}
                  className={clsx(
                    classes.avatar,
                    "dtTransition",
                    open ? classes.avatarOpen : null
                  )}
                >
                  {userData.name.charAt(0) + userData.lastname.charAt(0)}
                </Avatar>
              )}
            </Grid>
            <Grid item xs={6} className={classes.cardContent}>
              <Typography className={classes.name} color="textSecondary">
                {userData.name} {userData.lastname}
              </Typography>
              <Typography className={classes.ocupation} color="textSecondary">
                {userData.ocupation} | {userData.group}
              </Typography>
              <Typography color="textSecondary" className={classes.email}>
                {userData.email}
              </Typography>
              <Typography className={classes.phone} color="textSecondary">
                {userData.phone}
              </Typography>
            </Grid>
            <Grid item xs={3} className={classes.cardActions}>
              {open ? (
                <div>
                  <LikeButton
                    strapiId={`${strapiId}-${data.email}`}
                    title={data.email}
                    type={`UserHighLigh_${type}`}
                  />
                  <FavButton
                    strapiId={`${strapiId}-${data.email}`}
                    title={data.email}
                    type={`UserHighLigh_${type}`}
                  />
                </div>
              ) : (
                <IconButton
                  onClick={() => {
                    setOpen(open ? false : true)
                  }}
                >
                  <ExpandMoreIcon className={classes.iconExpand} />
                </IconButton>
              )}
            </Grid>
          </Grid>
          <Collapse in={open} timeout={500}>
            <div className={classes.contentCollapsed}>
              <MDReactComponent
                text={data.content}
                className={classes.contentCollapsed}
              />
              <div className={classes.expandIcons}>
                <IconButton
                  onClick={() => {
                    setOpen(open ? false : true)
                  }}
                >
                  <ExpandLessIcon className={classes.iconExpand} />
                </IconButton>
              </div>
            </div>
          </Collapse>
        </Paper>
      ) : null}
    </Grid>
  )
}

export default function Tpl({ data }) {
  const classes = stl()
  const [authSession, setAuthSession] = React.useState(false)

  useEffect(() => {
    if (!authSession || !authSession.uid) {
      firebase.auth().onAuthStateChanged(user => {
        setAuthSession(firebase.auth().currentUser)
      })
    }
  }, [authSession])

  let {
    subtitle,
    heading,
    items,
    type,
    strapiId
  } = data && data.strapiUserHighlight ? data.strapiUserHighlight : {};

  if (!data) {
    return (
      <Paper>
        <h2>...</h2>
      </Paper>
    )
  }

  return (
    <Layout section={subtitle}>
      <SEO title={subtitle} />
      <Grid container spacing={3} className="page-detail highLight-detail">
        <Grid item xs={12} sm={7} lg={7} className="page-detail-content">
          <Typography
            className="page-title"
            variant="h1"
            color="textPrimary"
            align="center"
          >
            {subtitle}
          </Typography>
          <div className={clsx(classes.wrapperHeadingText)}>
            {heading && (
              <MDReactComponent
                text={heading}
                className={classes.headingText}
              />
            )}
          </div>
          {authSession
            ? items.map((item, index) => {
                return <Card data={item} type={type} strapiId={strapiId} />
              })
            : null}
        </Grid>
        <Grid item xs={12} sm={5} lg={5} className="page-detail-sidebar">
          <div style={{ height: 100 }} />
          <TopButtons />
          <div style={{ height: 50 }} />
          <BlockNews />
        </Grid>
      </Grid>
    </Layout>
  )
}

export const query = graphql`
  query($type: String!) {
    strapiUserHighlight(type: { eq: $type }) {
      id
      type
      strapiId
      title
      type
      subtitle
      heading
      items {
        id
        email
        content
      }
      fields {
        slug
      }
    }
  }
`
